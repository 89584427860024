import AxiosService from '../common/AxiosService';
import { apis } from '../constants';

/**
 * Danh sách trọng lượng đã xuất của các cuộn (đã gộp theo idGoods)
 * @returns
 */
export const buildListByIdGoods = async () => {
    let result = [];
    try {
        const response = await AxiosService.post(apis.Url_Api_Post_DanhSachXuat1Phan, { idGoods: null });

        const { data } = response.data;
        if (!!data && response.data.status == 200) {
            var newList = data.reduce((prev, next) => {
                var index = prev.findIndex(i => i.idGoods == next.idGoods);
                if (index == -1) {
                    prev.push(next);
                } else {
                    prev[index].weightDeliver += next.weightDeliver;
                }
                return prev;
            }, []);
            result = newList;
        }
    } catch (e) {
        console.log('Error ', e);
    }
    // console.log("ID gôds", result)
    return result;
};
